<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mt-8">
        <vx-card>
           <div class="card-title">
               <h2 class="w-auto my-2">{{ LabelConstant.headingLabelDomainsZones }}</h2>
               <vs-button
                  title="Add New Domains"
                  @click="addDomainActive=true, addEditDomainTitle = 'Create New Domain'"              
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="small-btn bg-actionbutton float-right"
                  >{{ LabelConstant.buttonLabelAddNewDomain }}</vs-button>
            </div>
            <div class="common-table">      
                  <div class="table-row-select">
                     <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                     <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in recordList"
                     />
                     </vs-select>
                  </div>
                  <vs-table :data="domaininfo" ref="tableData" :max-items="perPage" pagination search>
                     <template slot="thead">
                     <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                     <vs-th sort-key="Zone_Name">{{ LabelConstant.tableHeaderLabelDomainName }}</vs-th>
                     <vs-th sort-key="IsActive">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                     <vs-th sort-key="sortableCreateDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                     </template>
                     <template slot-scope="{data}">
                     <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td width="90">
                           <div class="table-action">
                           <vs-button
                              title="Edit"                      
                              type="filled"
                              icon-pack="feather"
                              icon="icon-edit"
                              class="mr-2 float-left"
                              color="primary"
                              @click="editDomain(tr.Id), addEditDomainTitle = 'Edit Domain'"
                           ></vs-button>
                           <vs-button
                              title="Delete"
                              @click="deleteDomain(tr)"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-trash-2"
                              class="mr-0"
                           ></vs-button>
                           </div>
                        </vs-td>
                        <vs-td :data="tr.Zone_Name">{{tr.Zone_Name}}</vs-td>
                        <vs-td :data="tr.IsActive">
                           <vs-chip color="success" v-if="tr.IsActive">{{ LabelConstant.statusConditionLabelActive }}</vs-chip>
                           <vs-chip color="danger" v-else>{{ LabelConstant.statusConditionLabelInActive }}</vs-chip>
                        </vs-td>
                        <vs-td :data="tr.sortableCreateDate">{{tr.displayCreateDate}}</vs-td>
                     </vs-tr>
                     </template>
                  </vs-table>
                  <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{domaininfo.length|tableRecordNumberFormatter}} entries</p>
                  <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{domaininfo.length|tableRecordNumberFormatter}} entries)</p>
                  <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>                   
            </div>
                                        
        </vx-card>
      </div>
    </div>
    <!-- Add New Domain Modal -->
    <vs-popup class="tag-info" :title="addEditDomainTitle" :active.sync="addDomainActive">
      <form @submit.prevent="checkValidation()" autocomplete="off">
        <template>
          <div class="vx-row mb-3">
            <div class="vx-col input-text w-full">
              <div class="popup-icon">
                <img src="../../../src/assets/images/domain.png" />
              </div>
              <div class="vx-row">
                <div class="vx-col w-full mb-6">
                  <vs-input
                    type="text"
                    class="w-full"
                    name="domain"
                    label-placeholder="Domain"
                    v-model="domain"
                    v-validate="'required|min:2|max:300'"
                  />
                  <span class="text-danger error-msg">{{ errors.first('domain') }}</span>
                  <span class="text-danger error-msg" v-if="domainNameServerSideError">
                    {{ domainNameServerSideError }}</span>
                  <p>{{ LabelConstant.inputLabelDomainInfo }}</p>
                </div>
                <div class="vx-col w-full mb-6">
                  <vs-checkbox class="inline-flex" v-model="active">{{ LabelConstant.inputLableActive }}</vs-checkbox>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="float-right mt-6 mb-8">
          <vs-button type="filled" button="submit" color="primary">
            {{ addEditDomainTitle=='Edit Domain' ? LabelConstant.buttonLabelUpdateDomain : LabelConstant.buttonLabelCreateDomain }}
          </vs-button>
          <vs-button @click="cancelDomain()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
      </form>
    </vs-popup>

    <!-- Delete User Modal -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>{{ LabelConstant.popupHeadingLabelDeleteDomain }}</h4>
            <span class="deleted-no">{{ domain?domain:""}}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="danger" type="filled" @click="deleteD()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          @click="deletepopupActive=false"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>

    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
  </div>
</template>
<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
/* Filter */
import vSelect from "vue-select";
import { Validator } from "vee-validate";
import moment from "moment";
const dict = {
  custom: {
    domain: {
      required: "Please enter a domain",
      min: "Zone name must be more than 2 and less than 300 characters",
      max: "Zone name must be more than 2 and less than 300 characters"
    }
  }
};
Validator.localize("en", dict);
export default {
  components: {
    VxCard,
    vSelect,
    HelpModal
  },
  data() {
    return {
      addEditDomainTitle: 'Create New Domain',
      domainNameServerSideError: null,
      domain: null,
      active: true,
      DomainId: null,
      addDomainActive: false,
      popupActive: false,
      deletepopupActive: false,
      domaininfo: [],
      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    };
  },
  mounted() {
    this.getDomains();
  },
  methods: {
    cancelDomain() {
      this.DomainId = null;
      this.domain = null;
      this.active = true;
      this.addDomainActive = false;
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    closepopup() {
      this.popupActive = false;
    },
    async getDomains() {
      this.$vs.loading();
      await this.axios
        .get("/ws/Zone/GetZoneList")
        .then(response => {
          let data = response.data;
          this.domaininfo = data;
          this.domaininfo.map(function(manipulatedData) {
            manipulatedData.displayCreateDate = moment(manipulatedData.Date_Created).format("M/D/YYYY hh:mm A")
            manipulatedData.sortableCreateDate = new Date(manipulatedData.Date_Created);
            
            return manipulatedData;
          });
          
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async checkValidation() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.createDomain();
        }
      });
    },
    async createDomain() {
      this.$vs.loading();
      let input = {
        Zone_Name: this.domain,
        IsActive: this.active,
        Id: this.DomainId ? this.DomainId : "",
        Date_Created: new Date(),
        Date_Updated: new Date(),
        Updated_By: null,
        IsDeleted: false
      };
      this.domainNameServerSideError = null
      let url = this.DomainId
        ? "/ws/Zone/UpdateZone?id=" + this.DomainId
        : "/ws/Zone/CreateZone";
      await this.axios
        .post(url, input)
        .then(() => {
          this.domain = null;
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.active = true;
          this.addDomainActive = false;
          this.getDomains();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.domainNameServerSideError = e.response.data.Errors[0].Message
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async editDomain(id) {
      this.$vs.loading();
      await this.axios
        .get("/ws/Zone/GetZone?id=" + id)
        .then(response => {
          let data = response.data;
          this.domain = data.Zone_Name;
          this.active = data.IsActive;
          this.DomainId = data.Id;
          this.addDomainActive = true;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async deleteDomain(data) {
      this.domain = data.Zone_Name;
      this.DomainId = data.Id;
      this.deletepopupActive = true;
    },
    async deleteD() {
      this.$vs.loading();
      await this.axios
        .get("/ws/Zone/DeleteZone?id=" + this.DomainId)
        .then(() => {
          this.domain = null;
          this.active = true;
          this.DomainId = null;
          this.deletepopupActive = false;
          this.getDomains();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    }
  },
  computed: {
		isTableRecordEntriesDisplay(){
			if(this.domaininfo.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
        if(this.domaininfo.length > 0){
            if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
                return false
            }else{
                return true
            }
        }
    },
    showingDataFrom: function () {
        if(this.domaininfo.length > 0){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
        }
    },
    showingDataTo: function () {
        if(this.domaininfo.length > 0){
            let totalPages = this.$refs.tableData.getTotalPages
            if(totalPages == this.$refs.tableData.currentx){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
            }else{
                return ((this.$refs.tableData.currentx) * this.perPage)
            }
        }
    },
    totalSearchedData: function () {
        if(this.domaininfo.length > 0){
            return this.$refs.tableData.queriedResults.length
        }
    },
    showingSearchedDataTo: function () {
        if(this.domaininfo.length > 0){
            let totalPages = this.$refs.tableData.getTotalPagesSearch
            if(totalPages == this.$refs.tableData.currentx){
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
            }else{
                return ((this.$refs.tableData.currentx) * this.perPage)
            }
        }
    }
  }
};
</script>
